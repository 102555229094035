body {
    font-family: "Founders Grotesk Reg";
}

.MuiInputBase-input{
    font-family: "Founders Grotesk Reg";
}

.ag-theme-balham .ag-header{
    background-color: #0E3837;
}
.ag-theme-balham .ag-filter-toolpanel-header, 
.ag-theme-balham .ag-filter-toolpanel-search, 
.ag-theme-balham .ag-header-icon,
.ag-theme-balham .ag-status-bar, 
.ag-theme-balham .ag-header-row, 
.ag-theme-balham .ag-multi-filter-group-title-bar{
    color:#fff;
}
.ag-floating-filter-body .ag-input-field-input.ag-text-field-input{
    color:#000 !important;
}
.ag-theme-balham  .ag-row-selected{
    background-color: #cee0dd;
}

